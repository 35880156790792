import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import React, { Suspense } from 'react';
import ScrollToTop from './hooks/ScrollToTop.jsx';
import './index.css';
import Preloader from './components/preloader/Preloader.jsx';

// Lazy load the App component
const LazyApp = React.lazy(() => import('./App.jsx'));

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <ScrollToTop>
      {/* Wrap LazyApp with Suspense and show Preloader while loading */}
      <Suspense fallback={<Preloader />}>
        <LazyApp />
      </Suspense>
    </ScrollToTop>
  </BrowserRouter>,
);
