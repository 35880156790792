import React from 'react';
import "./preloader.css";

const Preloader = () => {
  return (
    <div className="preloader"
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'inherit'
      }}
    >
      <img
        width={45} height={50}
        style={{
          width: '45px',
          height: '50px',
          marginLeft: 'auto',
          marginRight: 'auto',
          animation: 'heartbeat 1.5s ease-in-out infinite',  // Apply scaling animation
        }}
        src="/img/broadview_logo.png" alt="logo"
      />
    </div>
  );
};

export default Preloader;
